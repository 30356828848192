import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  approveTransactionPreview,
  deleteOnGoingTransaction,
  getOnGoingTransaction,
  postBulkCompanyInvestor,
  postBulkInstruments,
  postOnGoingTransaction,
  postTransactionBonusDetail,
  postTransactionBuybackDetail,
  postTransactionRoundDetail,
  postTransactionSecondaryDetail,
  postTransactionSplitDetail,
  getAllTransactions,
  getBonusTransactions,
  getBuybackTransactions,
  getRoundsTransactions,
  getSecondaryTransactions,
  getSplitTransactions,
  updatePpsAndValuation,
  getDiscardEventRound,
  roundExcelUpload,
  ongoingTransactionRound,
  addQuickShareholder,
  getDiscardEventRound2,
  getSecondaryInvestors,
  getEsopExerciseTransactions,
  deleteFinancialData,
  getOngoingNonPricedRound,
  calculateSharePrice,
  deleteNonPricedRoundModel,
  getTransactionDocuments,
  uploadTransactionDocument,
  removeTransactionDocument,
  uploadTransactionNotes,
  getTransactionNotes,
  postTransactionEsopExerciseDetail,
} from "../api/transactionRound";
import { queryClient } from "./client";
import {
  randomAllTransactions,
  randomIndividualBonusTransactions,
  randomIndividualBuyBackTransactions,
  randomIndividualSecondaryTransactions,
  randomIndividualSplitTransactions,
  randomIndividualTransactions,
  randomIndividualTransactionsList,
  randomSecondaryInvestors,
  randomTransactionDocuments,
} from "./randomPlaceholderData";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetAllTransactions() {
  return useQuery({
    queryKey: "getAllTransactions",
    queryFn: getAllTransactions,
    placeholderData: randomAllTransactions(),
  });
}

export function useGetOngoingNonPricedRound() {
  return useQuery({
    queryKey: "getOngoingNonPricedRound",
    queryFn: getOngoingNonPricedRound,
    enabled: true,
    placeholderData: randomIndividualTransactionsList(),
  });
}

export function useDeleteNonPricedRoundModels() {
  return useMutation({
    mutationKey: "deleteNonPricedRoundModel",
    mutationFn: deleteNonPricedRoundModel,
    onSuccess: () => {
      queryClient.invalidateQueries("listofmodeling");
      handleEventForTracking({
        eventName: "deleteOnGoingTransaction",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteOnGoingTransaction",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetIndividualRoundDetail(eventId: string) {
  return useQuery({
    queryKey: ["getRoundTransactions", eventId],
    queryFn: getRoundsTransactions,
    placeholderData: randomIndividualTransactions(),
  });
}

export function useGetIndividualEsopExerciseDetail(eventId: string) {
  return useQuery({
    queryKey: ["geEsopExerciseTransactions", eventId],
    queryFn: getEsopExerciseTransactions,
    placeholderData: randomIndividualTransactions(),
  });
}

export function useGetIndividualSplitDetail(eventId: string) {
  return useQuery({
    queryKey: ["getIndividualSplitDetail", eventId],
    queryFn: getSplitTransactions,
    placeholderData: randomIndividualSplitTransactions(),
  });
}

export function useGetTransactionDocuments(eventId: string, companyId: string) {
  let enabled = false;
  if (eventId && eventId !== "") {
    enabled = true;
  }
  return useQuery({
    queryKey: ["getTransactionDocuments", eventId, companyId],
    queryFn: getTransactionDocuments,
    // placeholderData: randomTransactionDocuments(),
    enabled,
  });
}

export function useGetIndividualSecondaryDetail(eventId: string) {
  return useQuery({
    queryKey: ["getIndividualSecondary", eventId],
    queryFn: getSecondaryTransactions,
    placeholderData: randomIndividualSecondaryTransactions,
  });
}

export function useGetSecondaryInvestors() {
  return useQuery({
    queryKey: "getSecondaryInvestor",
    queryFn: getSecondaryInvestors,

    placeholderData: randomSecondaryInvestors,
  });
}

export function useGetIndividualBuybackDetail(eventId: string) {
  return useQuery({
    queryKey: ["getIndividualBuybackDetail", eventId],
    queryFn: getBuybackTransactions,
    placeholderData: randomIndividualBuyBackTransactions,
  });
}

export function useGetIndividualBonusDetail(eventId: string) {
  return useQuery({
    queryKey: ["getIndividualBonusDetail", eventId],
    queryFn: getBonusTransactions,
    placeholderData: randomIndividualBonusTransactions,
  });
}

export function usePostTransactionRoundDetail(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionRoundDetail", companyId],
    mutationFn: postTransactionRoundDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionRoundDetail",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionRoundDetail",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useRoundExcelUpload(companyId: string) {
  return useMutation({
    mutationKey: ["postRoundExcelUpload", companyId],
    mutationFn: roundExcelUpload,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postRoundExcelUpload",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postRoundExcelUpload",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useAddShareholder(companyId: string) {
  return useMutation({
    mutationKey: "addquickshareholder",
    mutationFn: addQuickShareholder,
    onSuccess: () => {
      queryClient.invalidateQueries("getInstrumentAndShareHolder");
      toast("Shareholder Added Successfully!", {
        type: "success",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "addquickshareholder",
        success: true,
        eventType: "API",
      });
    },
    onError: (err: any) => {
      toast(err.response.data.errorMessage, {
        type: "error",
        autoClose: 2000,
      });
      handleEventForTracking({
        eventName: "addquickshareholder",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useOngoingTransactionRound() {
  return useQuery({
    queryKey: ["ongoingTransactionRound"],
    queryFn: ongoingTransactionRound,
  });
}

export function usePostTransactionSecondaryDetail(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionSecondaryDetail", companyId],
    mutationFn: postTransactionSecondaryDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionSecondaryDetail",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionSecondaryDetail",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function usePostTransactionBuybackDetail(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionBuybackDetail", companyId],
    mutationFn: postTransactionBuybackDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionBuybackDetail",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionBuybackDetail",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function usePostTransactionBonusDetail(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionBonusDetail", companyId],
    mutationFn: postTransactionBonusDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionBonusDetail",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionBonusDetail",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function usePostBulkCompanyInvestor() {
  return useMutation({
    mutationKey: "postBulkCompanyInvestor",
    mutationFn: postBulkCompanyInvestor,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postBulkCompanyInvestor",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postBulkCompanyInvestor",
        success: false,
        eventType: "API",
      });
    },
  });
}

// eslint-disable-next-line quotes
export function usePostBulkInstruments() {
  return useMutation({
    mutationKey: "postBulkInstruments",
    mutationFn: postBulkInstruments,
    onSuccess: () => {
      queryClient.invalidateQueries("getInstrumentAndShareHolder");
      handleEventForTracking({
        eventName: "postBulkInstruments",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postBulkInstruments",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function usePostTransactionSplitDetail(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionSplitDetail", companyId],
    mutationFn: postTransactionSplitDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionSplitDetail",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionSplitDetail",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetOnGoingTransaction(companyId: string) {
  return useQuery({
    queryKey: ["getTransactionEsopDetail", companyId],
    queryFn: getOnGoingTransaction,
    select: (data) => data.data,
    enabled: false,
  });
}

export function usePostOnGoingTransaction() {
  return useMutation({
    mutationKey: "postOnGoingTransaction",
    mutationFn: postOnGoingTransaction,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postOnGoingTransaction",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postOnGoingTransaction",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteOnGoingTransaction(companyId: string) {
  return useMutation({
    mutationKey: "deleteOnGoingTransaction",
    mutationFn: deleteOnGoingTransaction,
    onSuccess: () => {
      queryClient.invalidateQueries("proformaEventSummary");
      queryClient.invalidateQueries(["getTransactionEsopDetail", companyId]);
      queryClient.refetchQueries(["getTransactionEsopDetail", companyId]);
      handleEventForTracking({
        eventName: "deleteOnGoingTransaction",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteOnGoingTransaction",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useApproveTransactionPreview() {
  return useMutation({
    mutationKey: "approveTransactionPreview",
    mutationFn: approveTransactionPreview,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "approveTransactionPreview",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "approveTransactionPreview",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDiscardQuickRound2() {
  return useMutation({
    mutationKey: "getDiscardEventRound2",
    mutationFn: getDiscardEventRound2,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "getDiscardEventRound2",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "getDiscardEventRound2",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDiscardQuickRound() {
  return useQuery({
    queryKey: "discardEvent",
    queryFn: getDiscardEventRound,
    enabled: false,
  });
}

export function useUpdatePpsAndValuation() {
  return useMutation({
    mutationKey: "updatePpsAndValuation",
    mutationFn: updatePpsAndValuation,
    onSuccess: () => {
      queryClient.invalidateQueries("getRoundTransactions");
      queryClient.refetchQueries("getRoundTransactions");
      queryClient.invalidateQueries("getAllTransactions");
      queryClient.refetchQueries("getAllTransactions");
      handleEventForTracking({
        eventName: "updatePpsAndValuation",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updatePpsAndValuation",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCalculateSharePrice(
  valuation: number,
  roundSize: number,
  autoDiluteEsop: boolean,
  postEsop: number
) {
  return useQuery({
    queryKey: [
      "calculateSharePrice",
      valuation,
      roundSize,
      autoDiluteEsop,
      postEsop,
    ],
    queryFn: calculateSharePrice,
    select: (data) => data,
  });
}

export function useAddTransactionDocument() {
  return useMutation({
    mutationKey: "addTransactionDocument",
    mutationFn: uploadTransactionDocument,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addTransactionDocument",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addTransactionDocument",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteTransactionDocument() {
  return useMutation({
    mutationKey: "deleteTransactionDocument",
    mutationFn: removeTransactionDocument,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "deleteTransactionDocument",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteTransactionDocument",
        success: false,
        eventType: "API",
      });
    },
  });
}
export function useGetTransactionNotes(companyId: string, eventId: string) {
  return useQuery({
    queryKey: ["getTransactionNotes", companyId, eventId],
    queryFn: getTransactionNotes,
  });
}

export function useAddTransactionNotes() {
  return useMutation({
    mutationKey: "addTransactionNotes",
    mutationFn: uploadTransactionNotes,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addTransactionNotes",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addTransactionNotes",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function usePostTransactionEsopExerciseDetails(companyId: string) {
  return useMutation({
    mutationKey: ["postTransactionEsopExerciseDetails", companyId],
    mutationFn: postTransactionEsopExerciseDetail,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "postTransactionEsopExerciseDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "postTransactionEsopExerciseDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}
