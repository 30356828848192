import { memo, useEffect, useMemo, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { Icon } from "@iconify/react";
import { useParams } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { tableCellStyle } from "../../components/TableComponent";
import { CTADropdown } from "../../components/shared/Dropdown";
import SearchInput from "../../components/shared/SearchInput";
import { Box, HStack } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { useGetUserRoleGrants } from "../../queries/auth";
import EmptyTableIllustration from "../reports/EmptyTableIllustration";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  DateOfMeetingRender,
  DirectorAttendedRender,
  DirectorNamesRender,
  DirectorToolTip,
  NameRender,
  TagsRender,
} from "./BoardMeetingAgComponent";
import {
  attendedDirectors,
  BoardMeetingModel,
} from "../../types/BoardMeetingsModels";
import { DirectorDetailDataModel } from "../../types/OnBoardDataModel";
import { AddEditMeetings } from "../../modals/AddEditMeetings";
import AlertDialog from "../../components/shared/AlertDialog";
import { useDeleteBoardMeetingDetails } from "../../queries/boardMeetingDetails";
import { queryClient } from "../../queries";
import { DocumentsPopup } from "../boardModule/DocPopup";

type BoardMeetingProps = {
  data: BoardMeetingModel[];
  directorData: DirectorDetailDataModel[];
  attendedInvestors: string[];
};

export default function BoardMeetingAgTable(props: BoardMeetingProps) {
  const { cId, token } = useParams() as {
    cId: string;
    token: string;
  };
  const boardMeetingData = props.data || [];
  const gridApi = useRef<any>(null);
  const { data: userRoleData } = useGetUserRoleGrants();
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isResigned, setIsResigned] = useState(false);

  const { mutate: deleteMeetingDetails, status } =
    useDeleteBoardMeetingDetails();

  const handleCheckboxChange = () => {
    setIsResigned(!isResigned);
  };

  const componentsRegistery = useMemo(
    () => ({
      name: memo(NameRender),
      directorName: memo(DirectorNamesRender),
      dateOfMeeting: memo(DateOfMeetingRender),
      noOfDirectorsAttended: memo(DirectorAttendedRender),
      tags: memo(TagsRender),
    }),
    []
  );

  const showNoRowsOverlay = () => (
    <EmptyTableIllustration text={`${"No Board meeting details found"}`} />
  );

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [dialog, setDialog] = useState<{
    open: boolean;
    name?: "Edit" | "Delete" | "Add" | "Documents" | "";
    data?: any;
  }>({
    name: "",
    open: false,
  });

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    // setPinnedBottomRowData(grid);
    // uploadFilter();
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  useEffect(() => {
    if (status === "success") {
      queryClient.invalidateQueries("getBoardMeetingDetails");
    }
  }, [status]);

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  function handleModifyAction(action: string, data: any, index: number) {
    if (action === "Edit") {
      setDialog({
        open: true,
        name: "Edit",
        data,
      });
    } else if (action === "Delete") {
      setDialog({
        open: true,
        name: "Delete",
        data,
      });
    } else if (action === "Documents") {
      setDialog({
        open: true,
        name: "Documents",
        data: data.id,
      });
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Meeting Name",
        field: "name",
        filter: "agSetColumnFilter",
        cellRenderer: "name",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        pinned: "left",
        initialWidth: 300,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
      },
      {
        headerName: "Director Names",
        field: "directorName",
        filter: "agSetColumnFilter",
        cellRenderer: "directorName",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        minWidth: 400,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        tooltipField: "directorTooltipField",
        tooltipComponent: (params: any) => {
                  if (params.data) {
                    return <DirectorToolTip {...params} />;
                  }
                  return <></>;
                },
      },
      {
        headerName: "No. of Directors attended",
        field: "noOfDirectorAttended",
        filter: "agNumberColumnFilter",
        cellRenderer: "noOfDirectorAttended",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Date of Meeting",
        field: "dateOfMeeting",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            // const cellDateValue = cellValue.split('T')[0];
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "dateOfMeeting",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Tags",
        field: "tags",
        filter: "agTextColumnFilter",
        cellRenderer: "tags",
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Notes",
        field: "notes",
        filter: "agTextColumnFilter",
        cellRenderer: "notes",
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "12px", "line-height": "18px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    [isResigned]
  );

  const rowData = useMemo(
    () =>
      boardMeetingData.map((meetingData, index) => {
        const actions = (
          <CTADropdown
            actions={[
              {
                name: "Edit",
                disabled: cId !== undefined,
              },
              {
                name: "Delete",
                disabled: cId !== undefined,
              },
              {
                name: "Documents",
                disabled: false,
              },
            ]}
            onAction={(action) => {
              handleModifyAction(action.name, meetingData, index);
            }}
          />
        );
        return {
          name: meetingData.name,
          directorName: meetingData.directorsAttended,
          directorTooltipField: meetingData.directorsAttended,
          noOfDirectorAttended: meetingData.noOfDirectorsAttended,
          dateOfMeeting: meetingData.dateOfMeeting,
          tags: meetingData.tags,
          notes: meetingData.notes,
          attendedInvestors: props.attendedInvestors,
          actions,
        };
      }),
    [boardMeetingData]
  );

  return (
    <>
      <div className="shadow-box border border-borderColor bg-white rounded-md px-2 py-6">
        <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start">
          <div>
            <GenericTableHeader
              heading={"Board Meetings"}
              subHeading={`${boardMeetingData.length} Meetings`}
            />
          </div>
          <HStack className="items-center justify-between w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
            <SearchInput
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
            {!cId && (
              <PrimaryCTAButton
                event-name="Delete Confirmation"
                className="px-4"
                onClick={() => {
                  setDialog({
                    open: true,
                    name: "Add",
                  });
                }}
                disabled={
                  userRoleData && userRoleData[0].role === "Admin Viewer"
                }
              >
                <Icon
                  icon="mdi:table-large-add"
                  width={18}
                  height={18}
                  className={`mr-1 border-transparent text-white bg-transparent`}
                />
                Add
              </PrimaryCTAButton>
            )}
            <HStack className="gap-4">
              <IconCTAButton
                value={"Columns"}
                onClick={() => openToolPanel("columns")}
                buttonSize={ButtonSize.SMALL}
                iconName={"fluent:column-triple-edit-20-regular"}
                className={`px-4 font-medium items-center flex flex-row ${
                  isColumnOpen ? "text-orange-501" : "text-gray-400"
                }`}
              />
              <IconCTAButton
                value={"Filters"}
                buttonSize={ButtonSize.SMALL}
                onClick={() => openToolPanel("filters")}
                iconName={"material-symbols:filter-alt"}
                className={`px-4 font-medium items-center flex flex-row ${
                  isFilterOpen ? "text-orange-501" : "text-gray-400"
                }`}
              />
            </HStack>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          {(dialog.name === "Edit" || dialog.name === "Add") && (
            <AddEditMeetings
              directorData={props.directorData}
              meetings={dialog.data}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
              mode={dialog.name}
            />
          )}
          {dialog.name === "Delete" && (
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={"Do you want to delete this Meeting Details?"}
              onPrimaryAction={() => {
                deleteMeetingDetails({
                  id: dialog.data.id,
                  companyId: dialog.data.companyId,
                });
                setDialog({ open: false });
              }}
              onSecondaryAction={() => setDialog({ open: false })}
              primaryActionText="Delete"
            />
          )}
          {dialog.name === "Documents" && (
            <DocumentsPopup
              id={dialog?.data || ""}
              onPrimaryAction={() => {
                setDialog({
                  open: true,
                  name: "Documents",
                  data: dialog.data,
                });
              }}
              onSecondaryAction={() => {
                setDialog({
                  open: false,
                  name: "Documents",
                });
              }}
              type="meeting"
            />
          )}
        </Dialog>
        <HStack className="justify-between w-full">
          <Box
            style={{
              height: "400px",
            }}
            className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
          >
            <AgGridReact
              sideBar={agConfigDashboardSideBar}
              onGridReady={(params) => {
                // Store the grid API referen
                gridApi.current = params;
              }}
              components={componentsRegistery}
              alwaysShowHorizontalScroll
              alwaysMultiSort
              animateRows={true}
              defaultColDef={defaultColDef}
              onFilterChanged={onAgGridFilterChanged}
              // onColumnEverythingChanged={uploadColumn}
              rowData={rowData}
              // onCellClicked={handleCellClick}
              columnDefs={columnDefs}
              pagination={false}
              suppressRowTransform={true}
              suppressCopyRowsToClipboard={true}
              suppressCopySingleCellRanges={true}
              suppressCellFocus={true}
              suppressMenuHide={false}
              // onColumnResized={uploadColumn}
              noRowsOverlayComponent={showNoRowsOverlay}
              tooltipShowDelay={1000}
              tooltipInteraction={true}
              rowClass={"border-t border-dashed"}
              gridOptions={gridOptions}
              overlayNoRowsTemplate={`<span style="padding: 6px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px; border-radius: 5px;">${
                isResigned
                  ? "No Resigned Director details found"
                  : "No ACtive Director details found"
              }</span>`}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            ></AgGridReact>
          </Box>
        </HStack>
      </div>
    </>
  );
}
