import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import { ShareholderTransactionModel } from "../../types/Shareholder";
import {
  getCompanyCurrency,
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  AmountInTransactionRender,
  AmountRender,
  BuyerOrSellerRender,
  DateRender,
  NumberOfSharesRender,
  PPSRender,
  PostHoldingSharesRender,
  PreHoldingSharesRender,
  SecurityClassRender,
  ShareNumbersRender,
  TypeRender,
} from "./ShareholderAGTransactionDetailsComponent";
import { Box, HStack } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import { UpdateInvestmentCurrency } from "../../modals/UpdateInvestmentCurrency";
import { ShareholderPPSTooltip } from "../dashboardPage/customTooltip";

function AgGridShareholderTransactionTable({
  isPreviewOnly,
  hideShareNumbers,
  transactions,
}: {
  isPreviewOnly: boolean;
  hideShareNumbers: boolean;
  transactions: ShareholderTransactionModel[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.shareholderTransactionColumnModel);
      setFilterSetting(data.data?.shareholderTransactionFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };
  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        shareholderTransactionColumnModel: columnState,
        shareholderTransactionFilterModel: filterState,
      },
    });
  };

  const cin = "";
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: ShareholderTransactionModel;
    index?: number;
    mode?: "Update Transaction";
  }>({
    open: false,
  });

  const componentsRegistery = useMemo(
    () => ({
      type: memo(TypeRender),
      numberOfShares: memo(NumberOfSharesRender),
      pps: memo(PPSRender),
      date: memo(DateRender),
      securityClass: memo(SecurityClassRender),
      shareNumbers: memo(ShareNumbersRender),
      preHoldingShares: memo(PreHoldingSharesRender),
      postHoldingShares: memo(PostHoldingSharesRender),
      amount: memo(AmountRender),
      amountInTransaction: memo(AmountInTransactionRender),
      buyerOrSeller: memo(BuyerOrSellerRender),
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  function handleModifyAction(
    action: string,
    data: ShareholderTransactionModel
  ) {
    if (action === "Update Transaction") {
      setDialog({
        open: true,
        mode: "Update Transaction",
        data,
      });
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () =>
      hideShareNumbers
        ? [
            {
              headerName: "Type",
              field: "type",
              filter: "agSetColumnFilter",
              pinned: "left",
              cellRenderer: "type",
              initialWidth: 200,
              sortable: true,
              autoHeight: true,
              cellStyle: tableCellStyle,
              wrapText: true,
              suppressSizeToFit: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipShareholderName",
            },
            {
              headerName: "No. of Securities",
              field: "numberOfShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "numberOfShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipNumberOfShares",
            },
            {
              headerName: `PPS (${currencySymbol})`,
              field: "pps",
              filter: "agNumberColumnFilter",
              cellRenderer: "pps",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPPS",
            },
            {
              headerName: "Date",
              field: "date",
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (dateFromFilter: Date, cellValue: any) => {
                  if (cellValue == null) {
                    return 0;
                  }
                  const dateParts = cellValue.split("-");
                  const day = Number(dateParts[2]);
                  const month = Number(dateParts[1]) - 1;
                  const year = Number(dateParts[0]);
                  const cellDate = new Date(year, month, day);
                  if (cellDate < dateFromFilter) {
                    return -1;
                  } else if (cellDate > dateFromFilter) {
                    return 1;
                  }
                  return 0;
                },
              },
              cellRenderer: "date",
              cellStyle: { "padding-top": "10px", "line-height": "20px" },
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "Security Class",
              field: "securityClass",
              filter: "agSetColumnFilter",
              cellRenderer: "securityClass",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },

            {
              headerName: "Pre Holding Shares",
              field: "preHoldingShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "preHoldingShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipPreHoldingShares",
            },
            {
              headerName: "Post Holding Shares",
              field: "postHoldingShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "postHoldingShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipPostHoldingShares",
            },
            {
              headerName: `Amount (${currencySymbol})`,
              field: "amount",
              filter: "agNumberColumnFilter",
              cellRenderer: "amount",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipAmount",
            },
            {
              headerName: "Amount (Transaction Currency)",
              field: "amountInTransaction",
              filter: "agMultiColumnFilter",
              cellRenderer: "amountInTransaction",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipAmountInTransaction",
              filterParams: {
                maxNumConditions: 5,
                buttons: ["reset"],
                filters: [
                  {
                    title: "Amount in Transaction Currency",
                    display: "subMenu",
                    filter: "agTextColumnFilter",
                    buttons: ["reset"],
                    filterValueGetter: (params: any) => params.value.amount,
                    filterParams: {
                      buttons: ["reset"],
                      valueGetter: (params: any) =>
                        params.getValue("amountInTransaction").amount,
                    },
                  },
                ],
              },
            },
            {
              headerName: "Buyer/Seller Name",
              field: "buyerOrSeller",
              filter: "agTextColumnFilter",
              cellRenderer: "buyerOrSeller",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
            },
            {
              headerName: "",
              field: "actions",
              pinned: "right",
              hide: false,
              width: 80,
              maxWidth: 80,
              filter: false,
              menuTabs: [],
              colId: "action-column",
              suppressNavigable: true,
              suppressColumnsToolPanel: true,
              cellStyle: { "padding-top": "10px", "line-height": "20px" },
              resizable: false,
              sortable: false,
              cellRendererParams: ({ value }: { value: any }) => value.props,
              cellRenderer: CTADropdown,
            },
          ]
        : [
            {
              headerName: "Type",
              field: "type",
              filter: "agSetColumnFilter",
              pinned: "left",
              cellRenderer: "type",
              initialWidth: 200,
              sortable: true,
              autoHeight: true,
              cellStyle: tableCellStyle,
              wrapText: true,
              suppressSizeToFit: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipShareholderName",
            },
            {
              headerName: "No. of Securities",
              field: "numberOfShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "numberOfShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipNumberOfShares",
            },
            {
              headerName: `PPS (${currencySymbol})`,
              field: "pps",
              filter: "agNumberColumnFilter",
              cellRenderer: "pps",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPPS",
            },
            {
              headerName: "Date",
              field: "date",
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (dateFromFilter: Date, cellValue: any) => {
                  if (cellValue == null) {
                    return 0;
                  }
                  const dateParts = cellValue.split("-");
                  const day = Number(dateParts[2]);
                  const month = Number(dateParts[1]) - 1;
                  const year = Number(dateParts[0]);
                  const cellDate = new Date(year, month, day);
                  if (cellDate < dateFromFilter) {
                    return -1;
                  } else if (cellDate > dateFromFilter) {
                    return 1;
                  }
                  return 0;
                },
              },
              cellRenderer: "date",
              cellStyle: { "padding-top": "10px", "line-height": "20px" },
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "Security Class",
              field: "securityClass",
              filter: "agSetColumnFilter",
              cellRenderer: "securityClass",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "Share Numbers",
              field: "shareNumbers",
              cellRenderer: "shareNumbers",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: [],
              tooltipField: "tooltipCurrentValue",
            },
            {
              headerName: "Pre Holding Shares",
              field: "preHoldingShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "preHoldingShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipPreHoldingShares",
            },
            {
              headerName: "Post Holding Shares",
              field: "postHoldingShares",
              filter: "agNumberColumnFilter",
              cellRenderer: "postHoldingShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipPostHoldingShares",
            },
            {
              headerName: `Amount (${currencySymbol})`,
              field: "amount",
              filter: "agNumberColumnFilter",
              cellRenderer: "amount",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipAmount",
            },
            {
              headerName: "Amount (Transaction Currency)",
              field: "amountInTransaction",
              filter: "agMultiColumnFilter",
              cellRenderer: "amountInTransaction",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
              tooltipField: "tooltipAmountInTransaction",
              filterParams: {
                maxNumConditions: 5,
                buttons: ["reset"],
                filters: [
                  {
                    title: "Amount in Transaction Currency",
                    display: "subMenu",
                    filter: "agTextColumnFilter",
                    buttons: ["reset"],
                    filterValueGetter: (params: any) => params.value.amount,
                    filterParams: {
                      buttons: ["reset"],
                      valueGetter: (params: any) =>
                        params.getValue("amountInTransaction").amount,
                    },
                  },
                ],
              },
            },
            {
              headerName: "Buyer/Seller Name",
              field: "buyerOrSeller",
              filter: "agTextColumnFilter",
              cellRenderer: "buyerOrSeller",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              hide: true,
            },
            {
              headerName: "",
              field: "actions",
              pinned: "right",
              hide: false,
              width: 80,
              maxWidth: 80,
              filter: false,
              menuTabs: [],
              colId: "action-column",
              suppressNavigable: true,
              suppressColumnsToolPanel: true,
              cellStyle: { "padding-top": "10px", "line-height": "20px" },
              resizable: false,
              sortable: false,
              cellRendererParams: ({ value }: { value: any }) => value.props,
              cellRenderer: CTADropdown,
            },
          ],
    [hideShareNumbers]
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const currency = getCompanyCurrency();
  const currencyAllowedTypes = ["buy", "sell"];
  const rowData = useMemo(
    () =>
      transactions?.map((template) => ({
        id: template.id,
        type: template.type,
        numberOfShares: template.numberOfShares,
        tooltipNumberOfShares:
          template.numberOfShares.toLocaleString(currencyType),
        pps: template.price,
        tooltipPPS: Math.abs(template.price).toLocaleString(currencyType),
        preHoldingShares: template.preHoldingShares,
        tooltipPreHoldingShares: template?.preHoldingShares
          ? Object.entries(template?.preHoldingShares)?.map((values: any) =>
              values[1].toLocaleString(currencyType)
            )
          : "",
        postHoldingShares: template.postHoldingShares,
        tooltipPostHoldingShares: template?.postHoldingShares
          ? Object.entries(template?.postHoldingShares)?.map((values: any) =>
              values[1].toLocaleString(currencyType)
            )
          : "",
        date: template.date,
        securityClass: template.securityClass,
        shareNumber: template.shareRanges,
        amount: template.amount,
        tooltipAmount: template.amount,
        amountInTransaction: {
          amount: Math.abs(template.amountInvestedInCurrency),
          currency:
            template.currency && template.currency !== ""
              ? template.currency
              : currency,
        },
        tooltipAmountInTransaction: Math.abs(
          template.amountInvestedInCurrency || 0
        ).toLocaleString(
          getCurrency(template.currency) || getCurrency(currency)
        ),
        buyerOrSeller: template.secondaryInvestorName,
        isPartlyPaid: template.isPartlyPaid,
        actualSharePrice: template.actualSharePrice,
        currencyType,
        currencySymbol,
        currency: currencyType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Update Transaction",
                disabled: !(
                  currencyAllowedTypes.includes(template.type) ?? false
                ),
              },
            ]}
            onAction={(action) => {
              handleModifyAction(action.name, template);
            }}
          />
        ),
      })),
    [transactions]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 1.6) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 1.2) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 0.8) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 0.6) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 0.4) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 0.2) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = rowData[selectedRowIndex];
      if (cellParams.column.getColId() === "transactionName") {
        //dirty comment
        // onClickTransactionName(template.transactionType, template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };

  return (
    <>
      <HStack className="items-center justify-between py-2 bg-white rounded-md shadow-box">
        <GenericTableHeader
          heading={"Transaction Details"}
          subHeading={"Transactions"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="items-center justify-end gap-4 py-2 mr-4">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Update Transaction" && dialog.data ? (
            <UpdateInvestmentCurrency
              transactionCurrency={dialog.data.currency}
              amountInCompanyCurrency={dialog.data.amount}
              amountInTransactionCurrency={Math.abs(
                dialog.data.amountInvestedInCurrency
              )}
              exchangeRate={dialog.data.exchangeRate}
              exchangeRateForRound={dialog.data.exchangeRateForRound}
              roundCurrency={dialog.data.roundCurrency}
              recordId={dialog.data.id}
              onPrimaryAction={() => {
                setDialog({ open: false });
              }}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
            />
          ) : (
            <div></div>
          )}
        </Dialog>
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            onColumnResized={uploadFilterAndColumn}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AgGridShareholderTransactionTable;
